<template>
  <div class="revenue">
    <page-title>粉丝订单收益</page-title>
    <b-card>
      <b-nav class="mb-3 mt-n2" fill tabs>
        <b-nav-item to="?type=cash" exact replace :active="type === 'cash'">现金收益</b-nav-item>
        <b-nav-item to="?type=balance" exact replace :active="type === 'balance'">余额收益</b-nav-item>
      </b-nav>

      <template v-if="loading">
        <ske block/>
        <ske block/>
        <ske block/>
        <ske block/>
        <ske block/>
      </template>

      <template v-else-if="orders.length">
        <p>
          <fa icon="info-circle"/>
          收益将在下单三日后的早10:00发放，计算时不包含邮费
        </p>

        <div class="text-muted text-center" v-if="isSmallScreen">左右滑动表格查看更多信息</div>

        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>粉丝</th>
              <th>发放时间</th>
              <th>订单金额</th>
              <th>收益</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr :key="item.source" v-for="item in orders">
              <td>
                <avatar :src="item.fromUser.headimgurl" size="1.5em"></avatar>
                {{item.fromUser.nickname}}
              </td>
              <td>
                <datetime :value="item.createTime"/>
              </td>
              <td>{{$rmb(item.paidMoney)}}</td>
              <td>{{$rmb(item.amount || item.value)}}</td>
              <td class="nowrap" v-if="type === 'balance'">
                <b-link :to="'/my/balance/details?id=' + item.id">查看</b-link>
              </td>
              <td class="nowrap" v-else>
                <b-link @click="withdraw(item)" v-if="item.status === '1'">提现</b-link>
                <b-link disabled v-else-if="item.status === '0'">已提现</b-link>
                <b-link disabled v-else-if="item.status === '2'">提现中</b-link>
                <b-link @click="withdraw(item)" v-else>提现失败</b-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <b-btn :disabled="fetching" @click="loadMore" block v-if="!end">
          <fa icon="spinner" spin v-if="fetching"/>
          加载更多
        </b-btn>
      </template>

      <empty icon="coins" v-else>暂无收益</empty>
    </b-card>
  </div>
</template>

<script>
import route from '@/mixins/route-data'

export default {
  name: 'revenue',
  title: '粉丝订单收益',
  mixins: [route('revenue', {alias: 'orders'})],
  data() {
    return {
      page: 1,
      fetching: false,
      end: false
    }
  },
  computed: {
    type() {
      const {type} = this.$route.query
      if (['balance', 'cash'].includes(type)) {
        return type
      }
      return 'balance'
    }
  },
  methods: {
    onLoad() {
      this.end = this.orders.length < 12
      this.page = 1
    },
    async loadMore() {
      try {
        this.fetching = true
        const results = await this.fetchRevenue({page: ++this.page})
        this.orders = this.orders.concat(results)
        this.end = results.length < 12
      } finally {
        this.fetching = false
      }
    },
    async withdraw(order) {
      const oldStatus = order.status
      try {
        order.status = '2'
        await this.$req.post('/api/user/personal_agent/redpacks/withdraw', {
          redpacknos: [order.redpackNo]
        })
        this.$alert.success('订单' + order.source + '已提现，请注意查收微信红包')
      } finally {
        order.status = oldStatus
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.revenue {
  .item {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $hr-border-color;
  }

  .table {
    white-space: nowrap;
  }

  ol {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: .5em;
    }
  }
}
</style>
