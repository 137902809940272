<template>
  <div class="referral">
    <page-title>我是代言人</page-title>
    <template v-if="loading">
      <b-card>
        <ske block></ske>
        <ske block></ske>
        <ske block></ske>
        <ske block></ske>
      </b-card>
    </template>

    <template v-else>
      <b-card class="income">
        <popup v-if="promotePoster && showRule" :src="promotePoster" @close="onClose"/>

        <b-row>
          <b-col>
            <div class="mb-1">历史累计收益</div>
            <div>
              <div class="value">{{$rmb(totalMoney)}}</div>
            </div>
            <div>
              <b-link to="revenue/cash" append v-if="availableMoney">
                提现
                <fa icon="coins"/>
              </b-link>
              <b-link to="revenue/cash" append v-else-if="!!totalMoney">收益明细</b-link>
            </div>
          </b-col>
          <divider vertical></divider>
          <b-col>
            <div class="mb-1">收益比例</div>
            <div>
              <div class="value">
                {{withdrawRatio | percent}}
                <span class="small text-muted" style="font-size: 12px;">粉丝订单金额</span>
              </div>
            </div>

            <div v-if="withdrawRatio < 0.2">
              <b-link @click="showRule = true">提升收益</b-link>
            </div>
            <div class="text-warning" v-else>
              <fa icon="medal" fad/>
              您已成为高级代言人
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card title="如何成为代言人">
        <ol>
          <li>保存您的
            <b-link @click="posterActive = true">专属代言人海报</b-link>
          </li>
          <li>将海报发送给好友或分享到朋友圈</li>
          <template v-if="!posterConfig">
            <li>
              <ske></ske>
            </li>
            <li>
              <ske></ske>
            </li>
          </template>
          <template v-else>
            <li>{{posterConfig.rewards[0]}}</li>
            <li>{{posterConfig.rewards[1]}}</li>
          </template>
        </ol>

        <b-btn block variant="primary"
               @click="posterActive = true; $gtag.event('show_poster')">
          <fa icon="image"/>
          查看我的专属代言人海报
        </b-btn>
      </b-card>

      <b-card v-if="posterActive">
        <b-card-title>
          专属代言人海报
          <b-link class="float-right small" @click="posterActive = false">收起</b-link>
        </b-card-title>
        <poster :profile="userProfile"/>
      </b-card>

      <b-card no-body>
        <cell-link icon="credit-card" :to="'/my/referral/revenue/' + (grade ? 'cash' : 'balance')"
                   id="revenueLink">
          我的收益
        </cell-link>
        <cell-link to="/my/referral/fans">
          <fa icon="users" slot="icon"/>
          我的粉丝
          <template slot="tail">
            <span class="text-primary">{{userProfile.fansCount}} 人</span>
          </template>
        </cell-link>
      </b-card>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'
import Poster from './Poster.vue'

export default {
  name: 'referral',
  title: '我是代言人',
  mixins: [routeData('userProfile')],
  components: {Poster},
  data() {
    return {
      posterActive: false,
      posterConfig: null,
      promotePoster: '',
      showRule: false
    }
  },
  watch: {
    showRule(val) {
      if (val && !this.promotePoster) {
        this.$req.get('/buckets/weixinshu:referral').then(data => {
          this.promotePoster = data.promotePoster
        })
      }
    }
  },
  computed: {
    totalMoney() {
      const {historyRedpacksMoney = 0, historyBalanceMoney = 0} = this.userProfile
      return historyRedpacksMoney + historyBalanceMoney
    },
    availableMoney() {
      return this.userProfile.availRedpacksMoney
    },
    withdrawRatio() {
      return this.userProfile.withdrawRatio
    },
    grade() {
      return this.userProfile.grade
    }
  },
  created() {
    this.$req.get('/buckets/weixinshu:referral').then(data => {
      this.posterConfig = data.poster
    })
  },
  methods: {
    onLoad() {
      if (!this.userProfile.grade) {
        // 仅普通代言人
        const rule = this.$ls.get('referral.poster')
        if (!rule || Date.now() > rule) {
          this.showRule = true
          this.$ls.remove('referral.poster')
        }
      }
    },
    onClose() {
      // 每三天自动弹出一次
      this.$ls.set('referral.poster', Date.now() + 3 * 24 * 3600 * 1000)
      this.showRule = false
    },
    focusPoster() {
      this.activeCell = 'poster'
      setTimeout(() => {
        this.$el.querySelector('.poster').scrollIntoView()
        window.scrollBy(0, -50)
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.referral {
  .value {
    color: $primary;
    font-size: 2em;
    line-height: 1.5;
  }

  .rule-poster {
    .title {
      color: $primary;
    }

    .rule-text {
      padding: 1rem;
      margin: 1rem auto;
      border-radius: $border-radius;
      border: 2px solid #F5DF94;
    }

    .square {
      margin: auto;
    }
  }

  ol {
    padding-left: 1.5em;
  }
}
</style>
