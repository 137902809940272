<template>
  <div class="poster">
    <div class="mb-2">{{isMobile ? '长按' : '右击'}}下方图片保存，左右滑动查看更多</div>
    <swiper :options="swiperOptions">
      <swiper-slide v-for="template in templates" :key="template">
        <square :src="getPoster(template)" height="177.88%" img/>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  name: 'poster',
  props: ['profile'],
  mixins: [require('@/mixins/swiper').default],
  data() {
    return {
      posterConfig: null,
      hover: false,
      poster: '',
      templates: [
        'personal-4',
        'personal-wb',
        'personal-cwj',
        'personal-xk',
        'personal-hk',
        'personal-hd'
      ],
      swiperOptions: {
        slidesPerView: 1.1,
        spaceBetween: 20
      }
    }
  },
  methods: {
    getPoster(template) {
      const profile = this.profile
      const ticket = profile.qrTicket

      const qrcode = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + encodeURIComponent(ticket)
      const nickname = profile.nickname
      const avatar = profile.headimgurl

      const params = {
        qrcode: qrcode,
        nickname: nickname,
        id: profile.id,
        avatar: avatar
      }

      return 'https://canvas.xinshu.me/generate/' + template + this.serialize(params)
    }
  }
}
</script>

<style scoped lang="scss">
  .poster {
    position: relative;
    user-select: none;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    box-shadow: $box-shadow;
  }
</style>
