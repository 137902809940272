<template>
  <div class="fans">
    <page-title>我的粉丝</page-title>
    <loading v-if="loading"/>
    <template v-else>
      <b-card>
        <template v-if="fans.length">
          <header class="mb-3">
            <fa icon="info-circle"/>
            粉丝指通过您的推广海报首次关注心书的用户
          </header>

          <div class="text-muted text-center" v-if="isSmallScreen">左右滑动表格查看更多信息</div>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>粉丝</th>
                <th>关注时间</th>
                <th>订单金额 / 数量</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in fans" :key="item.openid">
                <td>
                  <avatar :src="item.headimgurl" size="1.5em"/>
                  {{item.nickname}}
                </td>
                <td>
                  <datetime :value="item.followTime"/>
                </td>
                <td>
                  {{$rmb(item.orderMoney)}}
                  /
                  {{item.orderCount}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="btn-area" v-if="!fans.end">
            <b-btn block @click="loadMore" :disabled="fetching">
              <fa icon="spinner" spin v-if="fetching"/>
              加载更多
            </b-btn>
          </div>
        </template>

        <empty icon="users" v-else>暂无粉丝</empty>
      </b-card>

    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'

export default {
  name: 'fans',
  title: '我的粉丝',
  mixins: [routeData('fans')],
  data() {
    return {
      page: 1,
      fetching: false
    }
  },
  methods: {
    loadMore() {
      this.fetching = true
      return this.fetchFans({page: ++this.page}).then(data => {
        this.fans = this.fans.concat(data)
        this.fans.end = data.length < 12
        this.fetching = false
      }).catch(() => {
        this.fetching = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .fans {
    .table {
      white-space: nowrap;
    }

    .list {
      padding-left: $grid-gutter-width-base / 2;
      padding-right: $grid-gutter-width-base / 2;
    }
    .item {
      border-bottom: 1px solid $hr-border-color;
      margin-bottom: 1em;
      padding-bottom: 1em;
    }
  }
</style>
